<template>
    <div class="margin-bottom-50">
        <teleport to="#detail-menu-teleport" v-if="isCurrenPage">
            <div class="anchor-box">
                <div
                    v-for="p in pages"
                    :key="p.key"
                    v-text="p.label"
                    :class="{ active: pageActive === p.key }"
                    @click="scrollToPage(p.key)"
                />
            </div>
        </teleport>
        <div
            class="content-viewtitle"
            :class="{ active: isToggle }"
            @click="isToggle = $isMobile() ? true : !isToggle"
        >
            佣金明細
            <div class="info-icon" :tooltip="commissionTip" tooltip-touch />
            <div class="toggle-menu-icon" />
        </div>
        <transition name="dropdown" mode="out-in">
            <div class="commission-container" v-show="isToggle">
                <div
                    v-for="(p, index) in pages"
                    :key="p.key"
                    :ref="
                        (el) => {
                            pageRefs[p.key] = el
                        }
                    "
                    :data-page="p.key"
                    v-show="isKeepLoading ? index === 1 : true"
                >
                    <div
                        class="subtitle"
                        :class="{ active: pageToggleState[p.key] }"
                        @click="
                            ;(pageToggleState[p.key] = !pageToggleState[p.key]),
                                setGaEventFun(
                                    {
                                        label: `${p.key}${
                                            pageToggleState[p.key]
                                        }`,
                                        action: 'show-Table'
                                    },
                                    route
                                )
                        "
                    >
                        <div
                            class="title-text"
                            :class="isKeepLoading ? 'loading-message' : ''"
                            v-text="isKeepLoading ? '資料讀取中...' : p.label"
                        />
                        <div class="toggle-menu-icon" />
                    </div>

                    <div class="hr-wrapper">
                        <transition name="shrink" mode="out-in">
                            <div v-if="pageToggleState[p.key]" class="hr" />
                        </transition>
                    </div>
                    <transition name="dropdown" mode="out-in">
                        <Table
                            v-show="pageToggleState[p.key]"
                            :isLoading="isKeepLoading"
                            :columnConfig="columnConfig[p.key]"
                            :rows="tableShowData[p.key]"
                            :class="p.className"
                            emptyMessage="查無任何結果"
                        />
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import _ from 'lodash'
import smoothscroll from 'smoothscroll-polyfill'
import { ref, toRefs, reactive, computed, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { showErrorMessage, setGaEvent } from '@/assets/javascripts/util'
import { getTiaInsuranceCommissionDetailAPI } from '@/assets/javascripts/api'
import {
    insuranceCommissionBonusColumns,
    insuranceServiceAllowanceColumns,
    insuranceYearEndBonusColumns,
    insuranceContinuedBonusColumns
} from '@/assets/javascripts/tia/setting'
import Table from '@/components/Table.vue'

export default {
    name: 'InsuranceCommission',
    components: {
        Table
    },
    props: {
        insId: {
            type: String,
            default: ''
        },
        isCurrenPage: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        isPageLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        manCode: {
            type: [String, Number],
            default: ''
        }
    },
    setup(props) {
        const columnConfig = reactive({
            commissionBonus: insuranceCommissionBonusColumns,
            serviceAllowance: insuranceServiceAllowanceColumns,
            yearEndBonus: insuranceYearEndBonusColumns,
            continuedBonus: insuranceContinuedBonusColumns
        })
        const pages = reactive([
            {
                key: 'commissionBonus',
                label: '一般佣金',
                className: 'commission-bonus'
            },
            {
                key: 'serviceAllowance',
                label: '服務津貼',
                className: 'service-allowance'
            },
            {
                key: 'yearEndBonus',
                label: '年終獎金',
                className: 'year-end-bonus'
            },
            {
                key: 'continuedBonus',
                label: '繼續率獎金',
                className: 'continued-bonus'
            }
        ])

        const pageToggleState = reactive({
            commissionBonus: true,
            serviceAllowance: true,
            yearEndBonus: true,
            continuedBonus: true
        })

        const page = ref('commissionBonus')
        const isLoading = ref(false)
        const isToggle = ref(true)
        const pageRefs = ref([])
        const pageActive = ref('commissionBonus')
        const isClickMenu = ref(false)
        const commissionTip = ref('此保單已經發放之佣金/津貼/獎金明細')

        const route = useRoute()
        const setGaEventFun = setGaEvent

        //保單明細其他資料仍loading時，佣金明細畫面也繼續loading避免排版一直跳
        const isKeepLoading = computed(() => {
            const { isPageLoading } = toRefs(props)
            return isPageLoading.value || isLoading.value
        })

        // 拿佣金明細資料
        const listData = ref({})
        const tableShowData = computed(() => {
            let dataset = _.cloneDeep(listData.value)
            Object.keys(dataset).forEach((bonus) => {
                dataset[bonus] = transformData(dataset[bonus])
            })
            return dataset
        })
        const transformData = (rows) => {
            return rows?.map((row) => {
                row.kRate = {
                    value: row.kRate,
                    unit: row.kRateUnit
                }
                row.releaseRate = {
                    value: row.releaseRate,
                    unit: row.releaseRateUnit
                }
                return row
            })
        }
        const getInsuranceCommissionDetail = async () => {
            try {
                const { insId } = toRefs(props)
                const { manCode } = toRefs(props)
                const id = insId.value
                const manCodeValue = manCode.value
                isLoading.value = true
                const res = await getTiaInsuranceCommissionDetailAPI(
                    id,
                    manCodeValue
                )
                listData.value = res.data
            } catch (error) {
                showErrorMessage(
                    '查詢佣金明細時發生錯誤',
                    '請嘗試重新從保單查詢列表的「明細」按紐開啟此頁面，若有疑問請洽資訊客服'
                )
            } finally {
                isLoading.value = false
            }
        }
        getInsuranceCommissionDetail()

        // 手機版畫面互動
        const scrollToPage = (pageName) => {
            setGaEvent(
                { label: `${pageName}Menu`, action: 'click-CommissionMenu' },
                route
            )
            // use polyfill to fix safari not scroll smooth
            isClickMenu.value = true
            smoothscroll.polyfill()
            const headerOffset = 120
            const page = pageRefs.value[pageName]
            const pageTop = page.offsetTop
            const offsetPosition = pageTop - headerOffset
            pageActive.value = pageName

            // scrollIntoView 在iOS上不吃scroll-margin，故使用scrollTo
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            })
            setTimeout(() => {
                isClickMenu.value = false
            }, 800)
        }
        const getObserver = () => {
            const callback = (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !isClickMenu.value) {
                        page.value = entry.target.dataset?.page
                        pageActive.value = entry.target.dataset?.page
                    }
                })
            }
            const option = {
                rootMargin: '-50% 0% -50% 0%',
                threshold: 0
            }
            return new IntersectionObserver(callback, option)
        }
        const registerObserver = () => {
            pages.forEach((p) => {
                observer.observe(pageRefs.value[p.key])
            })
        }
        const observer = getObserver()
        onMounted(() => {
            registerObserver()
        })

        watch(isToggle, (newVal) => {
            setGaEvent(
                {
                    label: `showAllTable${newVal === false ? 'false' : 'true'}`,
                    action: 'show-Table'
                },
                route
            )
            pages.forEach((page) => {
                if (!newVal)
                    setTimeout(() => {
                        pageToggleState[page.key] = true
                    }, 400)
            })
        })

        watch(
            () => props.isCurrenPage,
            (newVal) => {
                if (newVal) scrollToPage('commissionBonus')
            }
        )

        return {
            columnConfig,
            tableShowData,
            pages,
            page,
            scrollToPage,
            isLoading,
            isToggle,
            pageRefs,
            isKeepLoading,
            pageToggleState,
            pageActive,
            isClickMenu,
            commissionTip,
            route,
            setGaEventFun
        }
    }
}
</script>

<style lang="scss" scoped>
.content-viewtitle {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    transition: margin 1s ease-in-out;
    color: $sixth-black;
    font-size: 24px;
    cursor: pointer;

    @media screen and (max-width: 576px) {
        font-size: 20px;
    }

    .info-icon {
        margin-left: 10px;
        width: 20px;
        height: 20px;
    }

    .toggle-menu-icon {
        height: 15px;
        width: 15px;
        margin-left: 15px;
        @media screen and (max-width: 576px) {
            display: none;
        }
    }
}

.subtitle {
    display: flex;
    align-items: center;
    .title-text {
        color: $sixth-black;
        font-size: 18px;
        font-weight: 500;
    }
    .toggle-menu-icon {
        height: 10px;
        width: 10px;
        margin-left: 10px;
    }
    @media screen and (max-width: 576px) {
        margin-bottom: 10px;
    }
}

.content-viewtitle,
.subtitle {
    cursor: pointer;
    .toggle-menu-icon {
        transition: transform 0.3s;
        transform: rotate(0deg);
    }

    &.active {
        .toggle-menu-icon {
            transform: rotate(90deg);
        }
    }
}

.loading-message {
    font-size: 14px;
    opacity: 0.4;
}

.hr-wrapper {
    height: 10px;
    display: flex;
    align-items: center;
    .hr {
        max-width: 100%;
        margin-bottom: 5px;
    }
    @media screen and (max-width: 576px) {
        display: none;
    }
}

.anchor-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 20px;

    div {
        width: 100%;
        border: 1px solid $fourth-blue;
        border-left: none;
        padding: 7px 0px;
        color: $fourth-blue;
        font-size: 12px;
        text-align: center;
        cursor: pointer;

        &:first-of-type {
            border-left: 1px solid $fourth-blue;
            border-radius: 3px 0 0 3px;
        }
        &:last-of-type {
            border-radius: 0 3px 3px 0;
        }

        &.active {
            background-color: $fourth-blue;
            color: $primary-white;
        }
    }
}

.commission-container {
    > div:not(:first-child) {
        padding-top: 20px;
        @media screen and (max-width: 576px) {
            padding-top: 30px;
        }
    }
    @media screen and (max-width: 576px) {
        display: block !important;
    }
}

.table-wrapper {
    :deep(.table-container:not(.card)) {
        margin-bottom: 20px;
        .row-container .row {
            padding: 0px;
        }
        .bonus-name {
            font-size: 12px;
        }
    }

    :deep(.table) {
        max-height: none;

        &.loading {
            .table-body {
                .row-container:nth-of-type(3) ~ .row-container {
                    display: none;
                }
            }
        }
    }

    :deep(.nodata-container) {
        margin: 0;

        .empty-result-icon {
            display: none;
        }

        .empty-message {
            -webkit-text-size-adjust: 100%;
            text-align: left;
            margin-top: 0px;
        }
    }

    :deep(.card) {
        @media screen and (max-width: 576px) {
            .row-container .row.body {
                .cell {
                    &.bonus-name {
                        order: -10;
                        width: 50%;
                        justify-content: flex-start;
                        font-weight: 600;

                        &:before {
                            display: none;
                        }
                    }
                    &.product-code {
                        order: -9;
                        width: 50%;
                        justify-content: flex-end;
                        font-weight: 600;

                        &:before {
                            display: none;
                        }
                    }
                    &.take-name {
                        padding-bottom: 10px;
                        border-bottom: 1px dashed $fourth-grey;
                    }
                    &.payment-count {
                        padding-bottom: 10px;
                        border-bottom: 1px dashed $fourth-grey;
                    }
                }
            }
        }
    }
}

.continued-bonus {
    :deep(.table-container .table-body) {
        .row-container .row {
            .bonus-name {
                @media screen and (min-width: 577px) {
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
